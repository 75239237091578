<template>
<div>
  <div class="container details-main">
    <!-- Кнопка возврата -->
    <div class="details-button--exit">
      <a @click="$route.params.fromCatalog ? $router.go(-1) : $router.push('/studio-catalog')"
        class="button-exit--img"></a>
      <a @click="$route.params.fromCatalog ? $router.go(-1) : $router.push('/studio-catalog')">
        {{$t('catalogDetails.exit')}}</a>
    </div>
    <!-- /Кнопка возврата -->
    <!-- Заголовок и поиск -->
      <div class="details-name row">
        <div class="col-8 name-object">
          <span>{{ obj.name }}</span>
          <input type="hidden" :value=obj.id class="model-id">
        </div>
      </div>
      <!-- /Заголовок и поиск -->
      <!-- Ссылка и откуда -->
      <div class="details-link--box">
        <!-- <div class="details-linkFrom details-from">
          <div class="details-img--linkFrom details-from--img"></div>
          <span class="text">{{ obj.country }}</span>
        </div> -->
        <div class="details-linkFrom details-link derails-link-studio">
          <div class="details-img--linkFrom details-link--img"></div>
          <a href="#" class="text">{{ obj.website }}</a>
        </div>
      </div>
      <!-- /Ссылка и откуда -->
      <!-- Описание и слайдер -->
      <div class="details-more studio-details row row-cols-2">
        <div class="details-more--info studio-details--more col-12 col-xxl-6">
          <span>
            {{ obj.description }}
          </span>
        </div>
        <div class="details-more--img col-12 col-xxl-6 details-studio">
          <img :src="obj.logo" alt="">
        </div>
      </div>
      <div class="catalogMan">
        <ExpoCatalogContent :manufacturer-name="obj.name"></ExpoCatalogContent>
      </div>
  </div>
  <ExpoFooter></ExpoFooter>
</div>
</template>


<script>
import ExpoCatalogContent from "@/components/ExpoCatalogContent";
import {directive} from 'vue-awesome-swiper';
import ExpoFooter from "@/components/ExpoFooter";
import {tr} from "@/utils";

export default {
  props: ["id", "manufacturer", "fromCatalog"],
  name: "StudioDetails",
  components: {
    ExpoCatalogContent,
    ExpoFooter
  },
  directives: {
    swiper: directive
  },
  computed: {
    obj() {
      return tr(this.manufacturer,this.$i18n.locale);
    },
  },
}

</script>

<style scoped>
  .details-studio img{
    object-fit: contain;
    width: 90%;
    height: 90%;
    user-select: none;
  }
  .catalogMan{
    padding-bottom: 10%;
  }
  .derails-link-studio{
    padding-top: 40px;
  }
</style>
