<template>
  <div class="main-catalog--content">
    <div class="row row row-cols-1 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 row-cols-xxl-6">
      <div class="col item-row item-row-1" v-for="o in manufacturerObjects" :key="o.id">
        <router-link :to="{ name: 'ObjectDetails', params: {id: o.id, objectId: o.objectId, fromCatalog: true}}">
          <div class="item-row--img item-row--img-1">
            <img :src="thumb(o.img1)" alt="">
          </div>
          <div class="item-row--from item-row--from-1">
            <span class="item-row-link">{{o.manufacturer}}</span>
          </div>
          <div class="item-row--name item-row--name-1">
            <span class="item-row-link"> {{o.name}}</span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import {imop, tr} from "@/utils";

export default {
  name: "ExpoCatalogContent",
  components: {},
  props: ["manufacturerName"],
  methods: {
    thumb(url) {
      return imop(url, 'c_thumb,h_175');
    },
  },
  computed: {
    manufacturerObjects() {
      return store.state.expoObjects.map(obj => tr(obj, this.$i18n.locale)).filter(obj => {
        return obj.manufacturer === this.manufacturerName;
      });
    }
  }
}
</script>
